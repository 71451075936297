import database from "../firebase/firestore";
import callFunction from "../firebase/functions";
import { uploadFile } from "../firebase/storage";
import { trimData } from "../utils/api";
import { reportError } from "../utils/error-handler";

const generateReportId = () =>
  database()
    .collection("reports")
    .doc().id;

export const submitPartialViolationReport = data => {
  const dataCopy = trimData(data);

  const reportId = generateReportId();

  const response = {
    id: reportId,
    ...dataCopy,
    files: []
  };

  return Promise.all(dataCopy.files.map(file => uploadFile(file, reportId)))
    .then(snapshots => {
      response.files = snapshots.map(({ metadata }) => metadata);
      return response.files.map(({ fullPath }) => fullPath);
    })
    .then(files =>
      callFunction("submitPartialViolationReport", {
        id: reportId,
        ...dataCopy,
        files
      })
    )
    .then(
      () => response,
      err => {
        reportError(err);
      }
    );
};

export const submitFullViolationReport = data => {
  const dataCopy = trimData(data);

  if (!dataCopy.id) {
    dataCopy.id = generateReportId();
  }

  return Promise.all(
    dataCopy.files.map(file =>
      typeof file === "string"
        ? file
        : uploadFile(file, dataCopy.id).then(({ metadata }) => metadata.fullPath)
    )
  ).then(files => callFunction("submitViolationReport", { ...dataCopy, files }));
};
