import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import IconArrow from "../assets/icons/arrow.svg";

import { useStickyPosition } from "../hooks/sticky-position";
import { normalizeText } from "../utils/text";

import styles from "./listbox.module.scss";

const SEARCH_MEMORY_DURATION = 1500;

const Listbox = ({
  id,
  labelledBy,
  options: optionsInput,
  onChange,
  value,
  placeholder,
  multiple,
  error,
  placeholderSelectable,
  buttonClassName,
  disabled
}) => {
  const wrapperRef = useRef();
  const listboxRef = useRef();
  const buttonRef = useRef();
  const popoverRef = useRef();

  const [expanded, setExpanded] = useState(false);
  const [popoverCoords, setPopoverCoords] = useState({});
  const [highlightedOption, setHighlightedOption] = useState(null);
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);

  const values = useMemo(() => {
    if (Array.isArray(value)) {
      return value;
    }
    if (value !== undefined) {
      return [value];
    }
    return [];
  }, [value]);

  const options = useMemo(
    () =>
      placeholder && placeholderSelectable && !multiple
        ? [
            {
              value: placeholder
            }
          ].concat(optionsInput)
        : optionsInput,
    [optionsInput, placeholder, placeholderSelectable, multiple]
  );

  const selectedOptions = useMemo(() => options.filter(({ key }) => values.includes(key)), [
    options,
    values
  ]);

  const displayValue = useMemo(
    () =>
      selectedOptions.length > 0 ? (
        selectedOptions.map(option => <span key={option.key}>{option.value}</span>)
      ) : (
        <span>{placeholder}</span>
      ),
    [selectedOptions]
  );

  const isAnythingSelected = useMemo(() => selectedOptions.length > 0, [selectedOptions]);

  const highlightedOptionId = useMemo(
    () => (highlightedOption ? `${id}-option-${highlightedOption.key}` : null),
    [highlightedOption]
  );

  const {
    calculateCoordinates: calculatePopoverCoordinates,
    attachScrollListener,
    removeScrollListener
  } = useStickyPosition({
    anchorRef: buttonRef,
    elementRef: popoverRef,
    callback: setPopoverCoords
  });

  const openPopover = useCallback(() => {
    attachScrollListener();
    calculatePopoverCoordinates();
    setExpanded(true);
  }, [attachScrollListener, calculatePopoverCoordinates, setExpanded]);

  const closePopover = useCallback(
    focusButton => {
      setExpanded(false);
      removeScrollListener();

      if (focusButton) {
        buttonRef.current.focus();
      }
    },
    [buttonRef, setExpanded, removeScrollListener]
  );

  const toggleOption = useCallback(
    option => {
      if (multiple) {
        if (values.includes(option.key)) {
          const valueIndex = values.findIndex(val => val === option.key);
          const newValues = [...values.slice(0, valueIndex), ...values.slice(valueIndex + 1)];
          onChange(newValues);
        } else {
          onChange([...values, option.key]);
        }
      } else {
        if (!values.includes(option.key)) {
          onChange(option.key);
        }
        closePopover(true);
      }
    },
    [values, multiple, onChange, closePopover]
  );

  const highlightNextOption = useCallback(() => {
    const highlightedOptionIndex = options.indexOf(highlightedOption);
    const nextHighlightedOption = options[(highlightedOptionIndex + 1) % options.length];
    setHighlightedOption(nextHighlightedOption);
  }, [options, highlightedOption, setHighlightedOption]);

  const highlightPreviousOption = useCallback(() => {
    let highlightedOptionIndex = options.indexOf(highlightedOption);
    if (highlightedOptionIndex <= 0) {
      highlightedOptionIndex = options.length;
    }
    const nextHighlightedOption = options[highlightedOptionIndex - 1];
    setHighlightedOption(nextHighlightedOption);
  }, [options, highlightedOption, setHighlightedOption]);

  useEffect(() => {
    if (!search) {
      return;
    }

    const normalizedSearch = normalizeText(search);

    const optionIndex = options.findIndex(option =>
      option.value.split(" ").some(optionNamePartial =>
        normalizeText(optionNamePartial)
          .toLowerCase()
          .startsWith(normalizedSearch)
      )
    );

    if (optionIndex > -1) {
      setHighlightedOption(options[optionIndex]);
      listboxRef.current.scrollTop =
        listboxRef.current.children[optionIndex + (placeholderSelectable ? 0 : 1)].offsetTop;
    }
  }, [search, options, setHighlightedOption, placeholderSelectable]);

  const updateSearch = useCallback(
    key => {
      clearTimeout(searchTimeout);
      setSearch(key === "Backspace" ? search.slice(0, search.length - 1) : search + key);
      setSearchTimeout(
        setTimeout(() => {
          setSearch("");
        }, SEARCH_MEMORY_DURATION)
      );
    },
    [search, setSearch]
  );

  const onPopoverKeyDown = useCallback(
    event => {
      switch (event.key) {
        case "ArrowDown":
          highlightNextOption();
          return;
        case "ArrowUp":
          highlightPreviousOption();
          return;
        case " ":
        case "Enter":
          event.preventDefault();
          toggleOption(highlightedOption);
          return;
        case "Tab":
          closePopover();
          return;
        case "Escape":
          closePopover(true);
          return;
        default:
          break;
      }

      // Key 8 is backspace
      if ((event.which >= 65 && event.which <= 90) || event.which === 8) {
        updateSearch(event.key);
      }
    },
    [
      highlightedOption,
      highlightNextOption,
      highlightPreviousOption,
      toggleOption,
      closePopover,
      updateSearch
    ]
  );

  const onButtonKeyDown = useCallback(
    event => {
      switch (event.key) {
        case "ArrowDown":
        case "ArrowUp":
          openPopover();
          break;
        default:
          break;
      }
    },
    [openPopover]
  );

  useEffect(() => {
    if (!placeholder && value === undefined && options.length > 0) {
      onChange(options[0].key);
    }
  }, [placeholder, value, options]);

  useEffect(() => {
    if (expanded) {
      listboxRef.current.focus();

      setHighlightedOption(isAnythingSelected ? selectedOptions[0] : options[0]);
    }
  }, [expanded]);

  useEffect(() => {
    if (expanded && multiple) {
      calculatePopoverCoordinates();
    }
  }, [value, multiple, expanded]);

  useEffect(() => {
    const clickListener = event => {
      if (
        !wrapperRef.current.contains(event.target) &&
        !listboxRef.current.contains(event.target) &&
        expanded
      ) {
        closePopover();
      }
    };

    document.addEventListener("click", clickListener);
    document.addEventListener("auxclick", clickListener);
    document.addEventListener("contextmenu", clickListener);

    return () => {
      document.removeEventListener("click", clickListener);
      document.removeEventListener("auxclick", clickListener);
      document.removeEventListener("contextmenu", clickListener);
    };
  }, [wrapperRef, listboxRef, expanded, closePopover]);

  return (
    <div
      id={id}
      className={`${styles.wrapper} ${isAnythingSelected ? "" : styles.empty} ${
        expanded ? styles.expanded : ""
      } ${error ? styles.error : ""}`}
      ref={wrapperRef}
    >
      <button
        type="button"
        aria-haspopup="listbox"
        aria-labelledby={`${labelledBy} ${id}-button`}
        id={`${id}-button`}
        className={`bp-between ${styles.button} ${buttonClassName}`}
        onClick={() => (expanded ? closePopover() : openPopover())}
        onKeyDown={onButtonKeyDown}
        disabled={disabled}
        ref={buttonRef}
      >
        <span className={styles.value}>{displayValue}</span>
        <span aria-hidden className={styles.arrow}>
          <IconArrow className="bp-stroke" />
        </span>
      </button>

      {typeof document !== "undefined"
        ? ReactDOM.createPortal(
            <div
              tabIndex="-1"
              aria-hidden={!expanded}
              className={`${styles.popover} ${!expanded ? styles.hidden : ""}`}
              ref={popoverRef}
              style={{
                top: popoverCoords.top,
                left: popoverCoords.left,
                width: popoverCoords.width
              }}
            >
              {/* I focus this element via code */}
              {/* eslint-disable jsx-a11y/aria-activedescendant-has-tabindex */}
              <ul
                aria-labelledby={labelledBy}
                role="listbox"
                tabIndex="-1"
                id={`${id}-listbox`}
                className={styles.list}
                aria-activedescendant={highlightedOptionId}
                ref={listboxRef}
                onKeyDown={onPopoverKeyDown}
              >
                {/* eslint-enable jsx-a11y/aria-activedescendant-has-tabindex */}
                {placeholder && (!placeholderSelectable || multiple) ? (
                  <li
                    aria-disabled
                    role="option"
                    id={`${id}-null-option`}
                    aria-selected={!isAnythingSelected}
                    className={styles.placeholder}
                  >
                    {placeholder}
                  </li>
                ) : null}

                {options.map(option => {
                  const isSelected = selectedOptions.includes(option);

                  /* Keyboard controls are applied on the listbox element */
                  /* eslint-disable jsx-a11y/click-events-have-key-events */
                  // I cannot add a key to the placeholder option since I
                  // rely on value being undefined when placeholder is selected
                  return (
                    <li
                      key={option.key || `${id}-placeholder`}
                      role="option"
                      id={`${id}-option-${option.key}`}
                      aria-selected={isSelected}
                      className={`${styles.option} ${
                        option === highlightedOption ? styles.hover : null
                      } ${option.hidden ? styles.hidden : null}`}
                      onMouseEnter={() => setHighlightedOption(option)}
                      onMouseLeave={() => setHighlightedOption(null)}
                      onClick={() => toggleOption(option)}
                    >
                      {option.value}
                    </li>
                  );
                  /* eslint-enable jsx-a11y/click-events-have-key-events */
                })}
              </ul>
            </div>,
            document.body
          )
        : null}

      <input name={id} readOnly tabIndex="-1" type="hidden" value={value || ""} />
    </div>
  );
};

Listbox.propTypes = {
  id: PropTypes.string.isRequired,
  labelledBy: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    }).isRequired
  ),
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string.isRequired), PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  error: PropTypes.bool,
  placeholderSelectable: PropTypes.bool,
  buttonClassName: PropTypes.string,
  disabled: PropTypes.bool
};

Listbox.defaultProps = {
  options: false,
  value: undefined,
  placeholder: "",
  multiple: false,
  error: false,
  placeholderSelectable: false,
  buttonClassName: "",
  disabled: false
};

export default Listbox;
