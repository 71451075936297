import React, { useState, useMemo, useEffect } from "react";
import { graphql } from "gatsby";
import { useQueryParam, StringParam } from "use-query-params";

import ALL_POLLING_STATIONS from "../../content/polling-stations-municipality.json";
// import CONSTITUENCY_NUMBERS from "../../content/constituency-numbers.json";
import MUNICIPALITY from "../../enums/municipality.json";
// import CONSTITUENCY from "../../enums/constituency.json";
import VIOLATION_TYPE from "../../enums/violation-type.json";

import { submitPartialViolationReport, submitFullViolationReport } from "../api/report-violation";

import IconArrow from "../assets/icons/arrow.svg";

import { useTranslations } from "../hooks/translations";
import { reportError } from "../utils/error-handler";
import { REQUIRED, PHONE, EMAIL, MAX_LENGTH } from "../utils/validators";
import SEO from "../components/seo";
import Button from "../components/button";
import Form from "../components/form";
import Dropdown from "../components/dropdown";
import FormInput from "../components/form-input";
import FileUpload from "../components/file-upload";
import Textarea from "../components/textarea";
import PhoneInput from "../components/phone-input";
import Modal from "../components/modal";
import StatusModal from "../components/status-modal";
import TermsCheckbox from "../components/terms-checkbox";
import RecaptchaMessage from "../components/recaptcha-message";
import ViolationsPreview from "../components/violations-preview";

import styles from "./report-violation.module.scss";

export const query = graphql`
  query ReportViolationQuery($locale: String!) {
    translations(locale: $locale) {
      pages {
        reportViolation
      }
      enums {
        violationType
        municipality
        constituency
      }
      violations
      form
      recaptcha
      ...LayoutTranslations
    }
  }
`;

const EMAIL_OR_PHONE_REQUIRED = (_, form) => form.email.value || form.phone.value;

const MAIN_FIELDS = {
  violationType: {
    value: "",
    required: true,
    validators: [
      {
        validate: REQUIRED,
        message: "violationTypeRequired"
      }
    ]
  },
  files: {
    value: [],
    validators: [
      {
        validate: MAX_LENGTH(10),
        message: "tooManyFiles"
      },
      {
        validate: files => files.every(({ size }) => size <= 50000000), // 50 MB
        message: "filesTooLarge"
      }
    ]
  },
  describe: {
    value: "",
    required: true,
    validators: [
      {
        validate: REQUIRED,
        message: "descriptionRequired"
      },
      {
        validate: MAX_LENGTH(4000),
        message: "descriptionTooLong"
      }
    ]
  }
};

const SECONDARY_FIELDS = {
  municipality: {
    value: "",
    required: true,
    validators: [
      {
        validate: REQUIRED,
        message: "municipalityRequired"
      }
    ]
  },
  constituency: {
    value: ""
  },
  pollingStation: {
    value: ""
  },
  phone: {
    value: "",
    validators: [
      {
        validate: EMAIL_OR_PHONE_REQUIRED,
        message: "emailOrPhoneRequired"
      },
      {
        validate: phone => !phone || PHONE(phone),
        message: "phoneFormat"
      }
    ],
    revalidateField: "email"
  },
  email: {
    value: "",
    validators: [
      {
        validate: EMAIL_OR_PHONE_REQUIRED,
        message: "emailOrPhoneRequired"
      },
      {
        validate: email => !email || EMAIL(email),
        message: "emailFormat"
      },
      {
        validate: email => !email || MAX_LENGTH(100)(email),
        message: "emailTooLong"
      }
    ],
    revalidateField: "phone"
  },
  name: {
    value: "",
    required: true,
    validators: [
      {
        validate: REQUIRED,
        message: "nameRequired"
      },
      {
        validate: MAX_LENGTH(100),
        message: "nameTooLong"
      }
    ]
  },
  surname: {
    value: "",
    required: true,
    validators: [
      {
        validate: REQUIRED,
        message: "surnameRequired"
      },
      {
        validate: MAX_LENGTH(100),
        message: "surnameTooLong"
      }
    ]
  },
  terms: {
    value: false,
    validators: [
      {
        validate: REQUIRED,
        message: "terms"
      }
    ]
  }
};

const prepareFullReportRequest = (formData, partialReportData = { id: null, files: [] }) => {
  const report = { ...formData };
  report.id = partialReportData.id;
  const trimmedPhone = report.phone && report.phone.trim();
  report.phone = trimmedPhone ? `+3706${trimmedPhone}` : null;
  report.pollingStation = report.pollingStation
    ? ALL_POLLING_STATIONS[report.municipality].find(
        station => station.name === report.pollingStation
      )
    : null;
  delete report.terms;

  report.files = report.files.map(file => {
    const uploadedFile = partialReportData.files.find(
      ({ name, size, contentType }) =>
        name === file.name && size === file.size && contentType === file.type
    );

    return uploadedFile ? uploadedFile.fullPath : file;
  });

  return report;
};

const ReportViolationPage = () => {
  const t = useTranslations("pages.reportViolation");

  const {
    violationType: violationTypeTranslations,
    municipality: municipalityNames
    // constituency: constituencyNames
  } = useTranslations("enums");

  const violationsDropdownValues = useMemo(
    () =>
      Object.keys(VIOLATION_TYPE).map(key => ({
        key,
        value: violationTypeTranslations[key]
      })),
    []
  );

  const municipalityDropdownValues = useMemo(
    () =>
      Object.keys(MUNICIPALITY).map(key => ({
        key,
        value: municipalityNames[key]
      })),
    []
  );

  // const constituencyDropdownValues = useMemo(
  //   () =>
  //     Object.keys(CONSTITUENCY).map(key => ({
  //       key,
  //       value: `${constituencyNames[key]} (nr. ${CONSTITUENCY_NUMBERS[key]})`
  //     })),
  //   []
  // );

  const [violation] = useQueryParam("violation", StringParam);

  const mainFields = useMemo(
    () => ({
      ...MAIN_FIELDS,
      violationType: { ...MAIN_FIELDS.violationType, value: violation }
    }),
    [violation]
  );

  const allFields = useMemo(() => ({ ...mainFields, ...SECONDARY_FIELDS }), [mainFields]);

  const [explanationVisible, setExplanationVisible] = useState(false);

  const [displayFullForm, setDisplayFullForm] = useState(false);

  const [partialReportPromise, setPartialReportPromise] = useState(null);

  const [reporting, setReporting] = useState(false);

  const [reportStatus, setReportStatus] = useState(null);

  const [pollingStationDropdownValues, setPollingStationDropdownValues] = useState([]);

  const submitFirstPart = formData => {
    if (!partialReportPromise) {
      setPartialReportPromise(submitPartialViolationReport({ ...formData }));
      setDisplayFullForm(true);
    }
  };

  const submitSecondPart = formData => {
    if (reporting) {
      return null;
    }

    setReporting(true);

    return partialReportPromise
      .then(partialReportData => prepareFullReportRequest(formData, partialReportData))
      .then(submitFullViolationReport)
      .then(
        () => {
          window.scrollTo({
            top: 0
          });
          setReportStatus({
            messages: t.success
          });
          setPartialReportPromise(null);
          // I want the form to reset before I update the fields
          setTimeout(() => {
            setDisplayFullForm(false);
          });
          return true;
        },
        err => {
          reportError(err);
          setReportStatus({
            error: true,
            messages: t.error
          });
        }
      )
      .finally(() => {
        setReporting(false);
      });
  };

  const updatePollingStationsDropdown = selectedMunicipality => {
    setPollingStationDropdownValues(
      ALL_POLLING_STATIONS[selectedMunicipality]
        ? ALL_POLLING_STATIONS[selectedMunicipality].map(station => ({
            key: station.name,
            value: station.name
          }))
        : []
    );
  };

  return (
    <>
      <SEO title={t.title} description={t.metaDescription} />
      <section>
        <div className="bp-container">
          <h1>{t.heading}</h1>
          <Form
            fields={displayFullForm ? allFields : mainFields}
            onSubmit={displayFullForm ? submitSecondPart : submitFirstPart}
            displayGlobalError
            errorMessages={t.errors}
          >
            {({ setFieldValue, fields }) => {
              useEffect(() => {
                if (fields.municipality) {
                  updatePollingStationsDropdown(fields.municipality.value);
                  setFieldValue("pollingStation", fields.pollingStation, "");
                }
              }, [fields.municipality]);

              return (
                <>
                  <Dropdown
                    id="violationType"
                    label={t.violationType}
                    placeholder={t.pleaseSelectViolation}
                    options={violationsDropdownValues}
                    field={fields.violationType}
                    onChange={setFieldValue}
                  />

                  <FileUpload
                    id="files"
                    label={
                      <>
                        {t.attach}{" "}
                        <button
                          type="button"
                          onClick={() => setExplanationVisible(true)}
                          className={`bp-link ${styles.explanationButton}`}
                        >
                          {t.howLargeFiles}
                        </button>
                      </>
                    }
                    placeholder={t.format}
                    dragPlaceholder={t.dropFiles}
                    field={fields.files}
                    onChange={setFieldValue}
                    removeLabel={t.removeFile}
                  />

                  <Textarea
                    id="describe"
                    label={t.describe}
                    field={fields.describe}
                    onChange={setFieldValue}
                  />

                  {displayFullForm && fields.municipality ? (
                    <>
                      <Dropdown
                        id="municipality"
                        label={t.municipality}
                        placeholder={t.pleaseSelectMunicipality}
                        options={municipalityDropdownValues}
                        field={fields.municipality}
                        onChange={setFieldValue}
                      />

                      {/* <Dropdown
                        id="constituency"
                        label={t.constituency}
                        placeholder={t.pleaseSelectConstituency}
                        options={constituencyDropdownValues}
                        field={fields.constituency}
                        onChange={setFieldValue}
                      /> */}

                      {pollingStationDropdownValues && (
                        <Dropdown
                          id="pollingStation"
                          label={t.pollingStation}
                          placeholder={t.pleaseSelectPollingStation}
                          options={pollingStationDropdownValues}
                          field={fields.pollingStation}
                          onChange={setFieldValue}
                        />
                      )}

                      <PhoneInput
                        id="phone"
                        label={
                          <>
                            {t.phone} <span className="bp-asterisk">({t.mandatoryIfNotEmail})</span>
                          </>
                        }
                        type="tel"
                        field={fields.phone}
                        onChange={setFieldValue}
                      />

                      <FormInput
                        id="email"
                        label={
                          <>
                            {t.email} <span className="bp-asterisk">({t.mandatoryIfNotPhone})</span>
                          </>
                        }
                        placeholder={t.email}
                        type="email"
                        field={fields.email}
                        onChange={setFieldValue}
                      />

                      <div className="bp-two-column">
                        <FormInput
                          id="name"
                          label={t.name}
                          field={fields.name}
                          onChange={setFieldValue}
                        />

                        <FormInput
                          id="surname"
                          label={t.surname}
                          field={fields.surname}
                          onChange={setFieldValue}
                        />
                      </div>

                      <TermsCheckbox field={fields.terms} onChange={setFieldValue} />

                      <RecaptchaMessage />

                      <Button
                        type="submit"
                        className="bp-standard bp-submit-button"
                        busy={reporting}
                      >
                        {t.register}
                      </Button>
                    </>
                  ) : (
                    <>
                      <RecaptchaMessage />

                      <Button type="submit" className={`bp-next ${styles.proceedButton}`}>
                        {t.proceed}
                        <IconArrow className="bp-stroke" />
                      </Button>
                    </>
                  )}
                </>
              );
            }}
          </Form>
        </div>
      </section>

      <ViolationsPreview />

      {reportStatus ? (
        <StatusModal
          error={reportStatus.error}
          heading={reportStatus.messages.heading}
          description={reportStatus.messages.description}
          onClose={() => setReportStatus(null)}
        />
      ) : null}

      {explanationVisible ? (
        <Modal onClose={() => setExplanationVisible(false)} className={styles.explanationModal}>
          <h1>{t.howLargeFiles}</h1>

          <p>{t.howLargeFiles1}</p>

          <ul className="bp-styled-list">
            <li>
              <a
                href="https://drive.google.com/"
                target="_blank"
                rel="noreferrer noopener"
                className="bp-link bp-accent"
              >
                Google Drive
              </a>
            </li>
            <li>
              <a
                href="https://www.dropbox.com/"
                target="_blank"
                rel="noreferrer noopener"
                className="bp-link bp-accent"
              >
                Dropbox
              </a>
            </li>
            <li>
              <a
                href="https://onedrive.live.com/"
                target="_blank"
                rel="noreferrer noopener"
                className="bp-link bp-accent"
              >
                Microsoft OneDrive
              </a>
            </li>
            <li>
              <a
                href="https://www.apple.com/icloud"
                target="_blank"
                rel="noreferrer noopener"
                className="bp-link bp-accent"
              >
                Apple iCloud
              </a>
            </li>
          </ul>

          <p>{t.howLargeFiles2}</p>
        </Modal>
      ) : null}
    </>
  );
};

export default ReportViolationPage;
