import React from "react";
import PropTypes from "prop-types";

import { FORM_FIELD } from "../prop-types";

import FormField from "./form-field";
import Listbox from "./listbox";

const Dropdown = ({ id, label, placeholder, options, field, onChange, multiple }) => (
  <FormField id={id} label={label} field={field} labelId={`${id}-label`}>
    <Listbox
      labelledBy={`${id}-label`}
      id={id}
      options={options}
      value={field.value}
      onChange={value => onChange(id, field, value)}
      placeholder={placeholder || label}
      error={!!field.error}
      multiple={multiple}
      placeholderSelectable={!field.required}
    />
  </FormField>
);

Dropdown.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  field: FORM_FIELD.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool
};

Dropdown.defaultProps = {
  placeholder: "",
  multiple: false
};

export default Dropdown;
