import React from "react";
import PropTypes from "prop-types";

import IconCheck from "../assets/icons/check.svg";
import { FORM_FIELD } from "../prop-types";

import styles from "./checkbox.module.scss";

const Checkbox = ({ id, children, field, onChange }) => (
  <div className={`${styles.wrapper} ${field.error && styles.invalid}`}>
    <input
      type="checkbox"
      id={id}
      className={`bp-visually-hidden ${styles.checkbox}`}
      aria-labelledby={`${id}-children`}
      checked={field.value}
      onChange={e => onChange(id, field, e.target.checked)}
    />
    <label htmlFor={id} className={styles.label}>
      {children}
    </label>
    <span className={`${styles.fakeCheckbox} ${field.error ? styles.error : ""}`}>
      <IconCheck className={`bp-stroke bp-3 ${styles.check}`} />
    </span>
    {field.error && <div className={`bp-error-message ${styles.errorMessage}`}>{field.error}</div>}
  </div>
);

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  field: FORM_FIELD.isRequired,
  onChange: PropTypes.func.isRequired
};

export default Checkbox;
