import firebase from "gatsby-plugin-firebase";

import { reportError } from "../utils/error-handler";

const STORAGE_BUCKET = process.env.GATSBY_FIREBASE_REPORTS_STORAGE_BUCKET;

export const uploadFile = (file, path) => {
  const storageRef = firebase
    .app()
    .storage(STORAGE_BUCKET)
    .ref();

  const pathRef = path ? storageRef.child(path) : storageRef;

  const fileRef = pathRef.child(file.name);

  return fileRef.put(file).catch(err => {
    reportError(err, "Failed to upload file");
    throw err;
  });
};

export const getDownloadURL = file =>
  firebase
    .app()
    .storage(STORAGE_BUCKET)
    .ref()
    .child(file)
    .getDownloadURL();
