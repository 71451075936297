import React from "react";
import PropTypes from "prop-types";

import { VIOLATION_ICONS } from "../../content/violation-icons";
import VIOLATION_TYPE from "../../enums/violation-type.json";

import IconArrow from "../assets/icons/arrow.svg";

import { useTranslations } from "../hooks/translations";

import LocalizedLink from "./localized-link";
import ButtonLink from "./button-link";

import styles from "./violations-preview.module.scss";

const ViolationsPreview = ({ displayRegisterButton }) => {
  const t = useTranslations("violations");

  const enums = useTranslations("enums");

  return (
    <section>
      <div className="bp-container">
        <h1>{t.heading}</h1>
        <p className="bp-subheading">{t.description}</p>

        {displayRegisterButton && (
          <ButtonLink className="bp-standard" to="/report-violation">
            {t.register}
          </ButtonLink>
        )}

        <div className="bp-cards bp-4">
          {Object.keys(VIOLATION_TYPE).map(key => (
            <LocalizedLink
              key={key}
              className={`bp-card bp-regular bp-interactive ${styles.violation}`}
              to={`/violations#${key}`}
            >
              <h2>{enums.violationType[key]}</h2>

              <div className={styles.image}>
                <img src={VIOLATION_ICONS[key]} width="140" height="140" alt="" />
              </div>

              <span className={styles.learnMore}>
                {t.learnMore} <IconArrow className={`bp-stroke ${styles.arrow}`} />
              </span>
            </LocalizedLink>
          ))}
        </div>
      </div>
    </section>
  );
};

ViolationsPreview.propTypes = {
  displayRegisterButton: PropTypes.bool
};

ViolationsPreview.defaultProps = {
  displayRegisterButton: false
};

export default ViolationsPreview;
