import React from "react";
import PropTypes from "prop-types";

import { useLinkTranslation } from "../hooks/link-translation";
import { FORM_FIELD } from "../prop-types";

import Checkbox from "./checkbox";

const TermsCheckbox = ({ id, field, onChange }) => {
  const privacyPolicyPath = useLinkTranslation("privacy-policy");

  return (
    <Checkbox id={id} field={field} onChange={onChange}>
      Pateikdamas(-a) užpildytą anketą patvirtinu, kad pateikta teisinga informacija ir sutinku, kad
      „Baltųjų pirštinių“ komanda su manimi susisiektų aukščiau nurodytais kontaktais ir tvarkytų
      mano asmens duomenis kaip tai reglamentuoja Lietuvos Respublikos bei Europos Sąjungos teisės
      aktai ir {/* eslint-disable-next-line react/jsx-no-target-blank */}
      <a href={privacyPolicyPath} target="_blank" rel="nofollow" className="bp-link bp-accent">
        organizacijos Privatumo politika
      </a>
      . Asmens duomenys bus naudojami rinkimų stebėjimo organizavimo tikslais.
    </Checkbox>
  );
};

TermsCheckbox.propTypes = {
  id: PropTypes.string,
  field: FORM_FIELD.isRequired,
  onChange: PropTypes.func.isRequired
};

TermsCheckbox.defaultProps = {
  id: "terms"
};

export default TermsCheckbox;
