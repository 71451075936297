import { useRef, useEffect, useCallback } from "react";
import { getScrollingParent } from "../utils/scrolling";

export const useStickyPosition = ({ anchorRef, elementRef, callback }) => {
  const scrollingParentRef = useRef(null);

  useEffect(() => {
    scrollingParentRef.current = getScrollingParent(anchorRef.current);
  }, [anchorRef]);

  const calculateCoordinates = useCallback(() => {
    const anchor = anchorRef.current;
    const element = elementRef.current;
    const offset = scrollingParentRef.current === window ? window.scrollY : 0;

    const { top, left, width, height } = anchor.getBoundingClientRect();

    const { height: elementHeight } = element.getBoundingClientRect();

    let proposedTop = top + height + offset;

    const doesElementOverflow =
      top + height + elementHeight + 24 > document.documentElement.clientHeight;

    if (doesElementOverflow) {
      proposedTop = top - elementHeight - 10 + offset;
    }

    callback({
      top: proposedTop,
      left,
      width
    });
  }, [anchorRef, elementRef, callback]);

  const attachScrollListener = useCallback(() => {
    const isWindowScroll = scrollingParentRef.current === window;

    (isWindowScroll ? document : scrollingParentRef.current).addEventListener(
      "scroll",
      calculateCoordinates
    );

    if (!isWindowScroll) {
      document.body.classList.add("bp-overflow-hidden");
    }
  }, [calculateCoordinates]);

  const removeScrollListener = useCallback(() => {
    const isWindowScroll = scrollingParentRef.current === window;

    (isWindowScroll ? document : scrollingParentRef.current).removeEventListener(
      "scroll",
      calculateCoordinates
    );

    if (!isWindowScroll) {
      document.body.classList.remove("bp-overflow-hidden");
    }
  }, [calculateCoordinates]);

  return {
    calculateCoordinates,
    attachScrollListener,
    removeScrollListener
  };
};
