import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";

import IconX from "../assets/icons/x.svg";

import { FORM_FIELD } from "../prop-types";

import FormField from "./form-field";
import Button from "./button";

import styles from "./file-upload.module.scss";

const FileUpload = ({ id, label, placeholder, dragPlaceholder, field, onChange, removeLabel }) => {
  const onDrop = useCallback(
    acceptedFiles => {
      onChange(id, field, [...field.value, ...acceptedFiles]);
    },
    [onChange]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const removeFile = useCallback(
    fileIndex => {
      field.value.splice(fileIndex, 1);
      onChange(id, field, [...field.value]);
    },
    [onChange]
  );

  return (
    <FormField id={id} label={label} field={field}>
      {/* eslint-disable react/jsx-props-no-spreading */}
      <div
        {...getRootProps()}
        className={`bp-input ${styles.uploadInput} ${field.error ? "bp-error" : null}`}
        role="button"
      >
        <input
          {...getInputProps()}
          className="bp-visually-hidden"
          id={id}
          accept="audio/*,video/*,image/*"
        />
        {isDragActive && dragPlaceholder ? (
          <span className={`bp-placeholder ${styles.dropFiles}`}>{dragPlaceholder}</span>
        ) : (
          <>
            {/* eslint-disable react/no-array-index-key */}
            {field.value.map(({ name }, fileIndex) => (
              <div key={`${name}-${fileIndex}`} className={`bp-between ${styles.uploadedFile}`}>
                {name}
                <Button
                  onClick={e => {
                    e.stopPropagation();
                    removeFile(fileIndex);
                  }}
                  aria-label={removeLabel}
                >
                  <IconX className="bp-stroke" />
                </Button>
              </div>
            ))}
            {/* eslint-enable react/no-array-index-key */}
            <span className="bp-placeholder">{placeholder || label}</span>
          </>
        )}
      </div>
      {/* eslint-enable react/jsx-props-no-spreading */}
    </FormField>
  );
};

FileUpload.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  placeholder: PropTypes.string,
  dragPlaceholder: PropTypes.string,
  field: FORM_FIELD.isRequired,
  onChange: PropTypes.func.isRequired,
  removeLabel: PropTypes.string.isRequired
};

FileUpload.defaultProps = {
  placeholder: "",
  dragPlaceholder: ""
};

export default FileUpload;
