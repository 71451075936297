import React from "react";
import PropTypes from "prop-types";

import FormField from "./form-field";

const formatPhoneNumber = phone => {
  if (!phone) {
    return "";
  }
  const phoneOnlyDigits = phone.replace(/[^\d]/g, "");
  const firstPart = phoneOnlyDigits.slice(0, 2);
  const secondPart = phoneOnlyDigits.slice(2, 7);

  return `+370 6${firstPart}${secondPart ? ` ${secondPart}` : ""}`;
};

const deformatPhoneNumber = phone => {
  if (!phone) {
    return "";
  }

  const phoneOnlyDigits = phone.replace(/[^\d]/g, "");

  if (!phoneOnlyDigits.startsWith("3706")) {
    return "";
  }

  return phoneOnlyDigits.replace(/^3706?/, "").slice(0, 7);
};

const PhoneInput = ({ id, label, field, onChange }) => (
  <FormField id={id} label={label} field={field}>
    <input
      type="tel"
      placeholder="+370 6xx xxxxx"
      id={id}
      name={id}
      onFocus={() => {
        if (!field.value) {
          onChange(id, field, " ");
        }
      }}
      onBlur={e => {
        if (!deformatPhoneNumber(e.target.value)) {
          onChange(id, field, null);
        }
      }}
      value={formatPhoneNumber(field.value)}
      onChange={e => onChange(id, field, deformatPhoneNumber(e.target.value) || " ")}
      className={field.error ? "bp-error" : null}
    />
  </FormField>
);

PhoneInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  field: PropTypes.shape({
    value: PropTypes.string,
    error: PropTypes.string
  }).isRequired,
  onChange: PropTypes.func.isRequired
};

export default PhoneInput;
