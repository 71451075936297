import React from "react";
import PropTypes from "prop-types";

import { FORM_FIELD } from "../prop-types";

import FormField from "./form-field";

const Textarea = ({ id, label, placeholder, field, onChange, className, labelClassName }) => (
  <FormField id={id} label={label} field={field} labelClassName={labelClassName}>
    <textarea
      id={id}
      placeholder={placeholder || label}
      rows="4"
      value={field.value}
      onChange={e => onChange(id, field, e.target.value)}
      className={`${field.error ? "bp-error" : null} ${className}`}
    />
  </FormField>
);

Textarea.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  placeholder: PropTypes.string,
  field: FORM_FIELD.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  labelClassName: PropTypes.string
};

Textarea.defaultProps = {
  placeholder: "",
  className: "",
  labelClassName: ""
};

export default Textarea;
